.dropdownCategorias{
    width:800px;
}

.cont_imagen_superfamilia_menu_categoria{
    max-width: 80px;
}


@media (max-width:768px){
    .dropdownCategorias{
        width:540px;
    }
}
@media (max-width:575px){
    .dropdownCategorias{
        width:400px;
    }
}
@media (max-width:400px){
    .dropdownCategorias{
        width:300px;
    }
    .cont_imagen_superfamilia_menu_categoria{
        max-width: 60px;
    }
}